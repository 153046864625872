/**
 * fonts requises pour le texte du Wysiwyg
 * à synchro avec :
 * - apps/bo/src/index.css
 * - apps/bo/src/ui/Wysiwyg.tsx
 * - apps/front/src/index.css
 */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed&display=swap');
/* fin des fonts wysiwyg */

body {
  margin: 0;
  font-family: 'IBM Plex Sans Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* desactivate credit FullpageJS, Option credit doesn't exist in react */
.fp-watermark {
  display: none;
}

.export-page {
  break-after: always;
}
